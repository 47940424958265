/* eslint-disable jsx-a11y/anchor-is-valid */
import classes from "./GridBotPage.module.css";
import GridBotImage from "../../image/GridBot.svg";
import Ellipse from "../../image/ellipse.svg";
import GridBotLink from "../../components/gridBotLink/GridBotLink";
import PayToIcon from "../../image/PayToIcon.svg";
import PayFromIcon from "../../image/FromPay.svg";
import WalletIcon from "../../image/WalletIcon.svg";
import PeopleIcon from "../../image/PeopleIcon.svg";
import Modal from "../../components/modal/Modal";
import { useEffect, useMemo, useState } from "react";
import ModalDeposit from "../../components/modalsGrid/ModalDeposit";
import ModalFromCash from "../../components/modalsGrid/ModalFromCash";
import ModalBalanceInfo from "../../components/modalsGrid/ModalBalanceInfo";
import ModalReferalInfo from "../../components/modalsGrid/ModalReferalInfo";
import Profile from "../../components/profile/Profile";
import { useTelegram } from "../../hooks/useTelegram";
import ModalBlockSystem from "../../components/modalsGrid/ModalBlockSystem";
import PageSpinner from "../../components/pageSpinner/PageSpinner";

const GridBotPage = ({ userBack, user, isLoading }) => {
  const { webApp } = useTelegram();
  const linkActionsArr = [
    {
      name: "Внести средства на счёт бота",
      imgUrl: PayToIcon,
      label: "Пополнение",
    },
    { name: "Вывести средства", imgUrl: PayFromIcon, label: "Вывод" },
    { name: "Посмотреть баланс", imgUrl: WalletIcon, label: "Баланс" },
    { name: "Реферальная структура", imgUrl: PeopleIcon, label: "Реферал" },
  ];
  const theme = webApp.colorScheme;

  const [showFullText, setShowFullText] = useState(false);

  const [activeModal, setActiveModal] = useState("");

  const chekModal = useMemo(() => {
    switch (activeModal) {
      case "Пополнение":
        return (
          <ModalDeposit theme={theme} onCloseModal={() => setActiveModal("")} />
        );
      case "Вывод":
        return (
          <ModalFromCash
            theme={theme}
            onCloseModal={() => setActiveModal("")}
          />
        );
      case "Баланс":
        return (
          <ModalBalanceInfo
            theme={theme}
            balance={userBack?.balance}
            cash_accruals={userBack?.cash_accruals}
          />
        );
      case "Реферал":
        return <ModalReferalInfo theme={theme} />;
      default:
        return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeModal]);

  const userName = `${user?.first_name ?? "N"} ${user?.last_name ?? "LN"}`;
  const nick = userBack?.username;

  const [checkCloser, setCheckCloser] = useState(true);

  useEffect(() => {
    if (
      userBack?.subscription_name === "strategist" ||
      userBack?.subscription_name === "lider" ||
      userBack?.subscription_name === "enthusiast"
    ) {
      setCheckCloser(false);
    }
  }, [userBack?.subscription_name]);

  return (
    <>
      {isLoading ? (
        <div className={classes.wrapper}>
          <PageSpinner />
        </div>
      ) : (
        <div className={classes.wrapper}>
          <>
            <Profile
              userName={userName}
              theme={theme}
              nick={nick}
              avatar={userBack?.avatar}
              subscription_name={userBack?.subscription_name}
            />
            <div className={classes.headerImage + " wrapper"}>
              <img
                src={GridBotImage}
                alt="griddBot"
                className={classes.botImg}
              />
              <img src={Ellipse} alt="Ellipse" className={classes.ellipse} />
              <div className={classes.headerTextWrapper}>
                <div className={classes.headerTitle}>Grid-бот</div>
                <div className={classes.headerDescr}>
                  {showFullText
                    ? "Встречайте новое слово в мире криптовалют — Grid-бот! Хотите максимизировать свои доходы на криптовалютном рынке с помощью искусственного интеллекта? С Grid-ботом это легко! Наш инновационный бот, основанный на передовых технологиях искусственного интеллекта, автоматически создает сетку ордеров для торговли криптовалютами, обеспечивая стабильный и эффективный способ заработка. Забудьте о сложностях ручного трейдинга! Grid-бот, снабженный мощными алгоритмами искусственного интеллекта, самостоятельно анализирует рынок, следит за изменениями цен и автоматически выполняет сделки в соответствии с вашими предпочтениями. Это идеальное решение для новичков и опытных трейдеров! Надежность, высокая производительность и простота использования — вот основные преимущества нашего Grid-бота, который объединяет силу криптовалют и искусственного интеллекта. Присоединяйтесь к нам сегодня и начните зарабатывать на криптовалюте легко и удобно! Подключайтесь к будущему финансовых технологий с Grid-ботом — вашим надежным партнером на пути к финансовой независимости!"
                    : "Подключайтесь к будущему финансовых технологий с Grid-ботом — вашим надежным партнером на пути к финансовой независимости!"}
                </div>
                <div
                  className={classes.headerInfo}
                  onClick={() => setShowFullText(!showFullText)}
                >
                  {showFullText ? "Скрыть" : "Подробнее"}
                </div>
              </div>
            </div>

            {linkActionsArr.map((item) => (
              <GridBotLink
                key={item.name}
                {...item}
                handleClick={() => {
                  setActiveModal(item.label);
                  setShowFullText(false);
                }}
                theme={theme}
              />
            ))}
            {!!activeModal.length && (
              <Modal onCloseModal={() => setActiveModal("")} theme={theme}>
                {chekModal}
              </Modal>
            )}

            {checkCloser && (
              <Modal onCloseModal={() => {}} theme={theme} noClose={true}>
                <ModalBlockSystem theme={theme} />
              </Modal>
            )}
          </>
        </div>
      )}
    </>
  );
};

export default GridBotPage;
